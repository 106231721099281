import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import usePageStyles from '../hooks/usePageStyles';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import CategoryJournalsList from '../components/JournalsList/CategoryJournalsList';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { getCategoryArchiveUrl } from '../utilities/permalinks';

function Category(props) {
  // usePageStyles();
  usePageStyles({ backgroundColor: '#929598' });

  const {
    data: { wpJournalCategory },
  } = props;

  const {
    journals: { nodes: allJournals },
  } = wpJournalCategory;

  // prepare breadcrumbs
  const breadCrumbs = [];
  if (wpJournalCategory?.journalCategoryFields?.subtitle) {
    breadCrumbs.push({
      label: `${wpJournalCategory.name} - ${wpJournalCategory.journalCategoryFields.subtitle}`,
    });
  } else {
    breadCrumbs.push({ label: `${wpJournalCategory.name}` });
  }
  return (
    <>
      <Seo post={wpJournalCategory} />
      <HeaderSpacer />
      <Breadcrumbs data={breadCrumbs} />
      <CategoryJournalsList
        journals={allJournals}
        categoryBreadcrumbs={[
          {
            label: `${wpJournalCategory.name}`,
            url: getCategoryArchiveUrl(wpJournalCategory.slug),
          },
        ]}
      />
    </>
  );
}

Category.propTypes = {
  data: PropTypes.shape({
    wpJournalCategory: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      journalCategoryFields: PropTypes.shape({
        subtitle: PropTypes.string,
      }),
      journals: PropTypes.shape({
        nodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
export default Category;

export const query = graphql`
  query categoryQuery($categoryId: String) {
    wpJournalCategory(id: { eq: $categoryId }) {
      seo {
        ...YoastTaxonomySEOContent
      }
      id
      name
      slug
      journalCategoryFields {
        subtitle
      }
      journals {
        nodes {
          ...CategoryJournalsListingFields
        }
      }
    }
  }
`;
