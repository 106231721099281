import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './CategoryJournalsList.module.scss';
import { getSingleJournalUrl } from '../../utilities/permalinks';
import EmptyContent from '../Helpers/EmptyContent';

function CategoryJournalsList(props) {
  const { journals, categoryBreadcrumbs } = props;

  const renderImage = (image) => {
    const width = image?.localFile?.originalImage?.original?.width;
    const height = image?.localFile?.originalImage?.original?.height;
    if (width / height > 1) {
      // landscape image
      return (
        <>
          <GatsbyImage className="d-md-none" image={getImage(image.localFile.mobileImageLandscape)} alt={image.altText} />
          <GatsbyImage className="d-none d-md-block" image={getImage(image.localFile.desktopImageLandscape)} alt={image.altText} />
        </>
      );
    }
    // portrait image
    return (
      <>
        <GatsbyImage className="d-md-none" image={getImage(image.localFile.mobileImagePortrait)} alt={image.altText} />
        <GatsbyImage className="d-none d-md-block" image={getImage(image.localFile.desktopImagePortrait)} alt={image.altText} />
      </>
    );
  };

  const renderJournals = () => (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-6">
          <Accordion
            className={styles.categoryJournalsList}
            alwaysOpen
            defaultActiveKey={Array(journals.length).fill().map((_, index) => index)}
          >
            {journals.map((journal, journalIndex) => (
              <Accordion.Item eventKey={journalIndex} key={journal.id}>
                <Accordion.Header className={styles.journalTitle} as="div">
                  {journal.title}
                </Accordion.Header>
                <Accordion.Body className={styles.journalGallery} as="ul">
                  {/* consider adding a check if gallery has images */}
                  {journal.journalContent.postGallery.map((image, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${journal.id}-image-${index}`}>
                      <Link
                        to={getSingleJournalUrl(journal.slug)}
                        state={{ breadcrumbs: categoryBreadcrumbs }}
                      >
                        {renderImage(image)}
                      </Link>
                    </li>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );

  const renderEmpty = () => (
    <EmptyContent message="No posts found in this category." />
  );

  const renderContent = () => {
    if (journals.length) {
      return renderJournals();
    }

    return renderEmpty();
  };

  return <>{renderContent()}</>;
}

CategoryJournalsList.defaultProps = {
  journals: [],
  categoryBreadcrumbs: null,
};
CategoryJournalsList.propTypes = {
  journals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      journalContent: PropTypes.shape({
        fieldGroupName: PropTypes.string,
        postGallery: PropTypes.arrayOf(
          PropTypes.shape({
            altText: PropTypes.string,
            localFile: PropTypes.shape({
              originalImage: PropTypes.shape({}).isRequired,
              mobileImagePortrait: PropTypes.shape({}).isRequired,
              mobileImageLandscape: PropTypes.shape({}).isRequired,
              desktopImagePortrait: PropTypes.shape({}).isRequired,
              desktopImageLandscape: PropTypes.shape({}).isRequired,
            }),
          }),
        ),
      }),
    }),
  ),
  categoryBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({

  })),
};
export default CategoryJournalsList;
